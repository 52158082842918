<template>
    <div class="UploadImage" v-loading="file.loading">
        <Upload @upload="upload" :fields="fields">
            <div slot="button" class="UploadImageBtn">
                <el-image v-if="file.fileName" :src="file.fileName | fileAddress" class="image"></el-image>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </div>
        </Upload>
        <div class="operation" v-if="file.fileName">
            <i class="el-icon-delete" @click="del"></i>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            disabled: {
                type: Boolean,
                default: false
            },
            fields:{
                type: Object,
                default:function(){
                    return {
                        fileName:'fileName',
                        fileAddress:'fileAddress'
                    }
                }
            },
            data:{
                type: Object,
                default:function(){
                    return {
                        loading:false,
                        fileName:'',
                        fileAddress:'',
                    }
                }
            },
        },
        components: {
            Upload: () => import("./Upload.vue"),
        },
        model: {
            prop: 'value',
            event: 'change'
        },
        data() {
            return {
                file:this.data.combination({
                    loading:false,
                }),
            };
        },
        computed: {},
        watch: {
            file:{
                deep: true,
                handler: function (newVal){
                    if(!newVal.loading){
                        this.$emit('change', newVal);
                    }
                }
            },
        },
        mounted() {},
        methods: {
            upload(r){
                this.file = r[0];
            },
            del(){
                this.file = {
                    loading:false,
                    fileName:'',
                    fileAddress:'',
                };
            },
        }
    };
</script>

<style lang="scss" scoped>
    .UploadImage {
        position:relative;
        display:inline-block;
        text-align:center;
        &:hover {
            .operation {
                opacity:1;
                transition:.1s cubic-bezier(.92,1.1,.71,.94);
            }
        }
        .operation {
            position:absolute;
            left:0;
            right:0;
            bottom:0;
            background:rgba(0,0,0,.5);
            opacity:0;
            height:45px;
            line-height:45px;
            padding:0 15px;
            text-align:right;
            .el-icon-delete {
                cursor:pointer;
                color:#fff;
                font-size:20px;
            }
        }
        .el-image {
            display:block;
        }
        /deep/.UploadImageBtn{
            font-size: 28px;
            color: #8c939d;
            width: 178px;
            height: 178px;
            line-height: 178px;
            text-align: center;
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            .image {
                max-width:100%;
                max-height:100%;
            }
        }
    }
</style>
